import { Getter } from "vuex";
import { IState } from './state';

const menuItems: Getter<IState, IState> = (state) => state.menuItems
const root: Getter<IState, IState> = (state) => state.root

export default {
	menuItems,
	root
}
