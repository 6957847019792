
import Vue from 'vue'
import MenuItem from './components/MenuItem.vue';
import { IMenuItem, IRootItem } from './store/state';

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	menuItems: IMenuItem[];
	root: IRootItem
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		menuItems() {
            return this.$store.getters.menuItems;
        },
		root() {
			return this.$store.getters.root;
		}
	},
	components: {
		MenuItem
	}
})
