export interface IState {
	root: IRootItem
	menuItems: IMenuItem[]
	endpoints: {
		getChildren: string
	}
}
export interface IMenuItem {
	name: string,
	uri: string,
	isCurrent: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IMenuItem[],
	level: number
}
export interface IRootItem {
	name: string,
	uri: string,
	isRoot: boolean,
}

const deafultState: IState = {
	root: {
		name: '',
		uri: '',
		isRoot: false,
	},
	menuItems: [],
	endpoints: {
		getChildren: ''
	}
}

export default deafultState;
